<template>
  <v-sheet
    outlined
    rounded
    class="rounded-lg fill-width"
  >
    <section class="pa-5 d-flex align-center">
      <slot name="header" />
    </section>
    <v-divider />
    <section
      class="accent px-5 py-7"
      :class="{
        'information-panel--restricted': restricted
      }"
    >
      <slot name="content" />
    </section>
  </v-sheet>
</template>

<script>
export default {
  name: 'InformationPanel',
  props: {
    restricted: {
      type: Boolean,
      required: false,
      default: () => (false),
    },
  },
};
</script>

<style lang="scss" scoped>
.information-panel--restricted {
  max-height: 200px;
  overflow-y: auto;
}
</style>
